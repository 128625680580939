import React, { useState, useEffect } from "react";
import DugolyBlog from "dugoly-blog";
import { getBlogTitle } from "../firebase/DBConnection";
import Ads from 'dugoly-shared-advertising'
import Bloglist from './BlogList'
import BlogList from "./BlogList";


const Blog = (props) => {
  const ownerid = props.match.params.ownerid;
  const bcode = props.match.params.bcode;
  const [BlogTitle, setBlogTitle] = useState("");
  const [BlogDir, setBlogDir] = useState("ltr");
  useEffect(() => {
    getBlogTitle(ownerid, bcode)
      .then((title) => {
      
        setBlogTitle(title.blogTitle);
        setBlogDir(title.dir);
      })
      .catch((e) => {
        console.log("e:  ", e);
      });
  }, []);


  return (
    <div>
       <Ads 
      imgPath="http://www.easydone.online/uploads/-M_PtQR42NCr_cSbLWgb/6b052fa6-9861-4ec7-8cf6-2c58378ca84a.gif"
link="https://motto.co.il/Promotion" 
language="he"  />
<br /><br/>
      <DugolyBlog ownerUid={ownerid} bCode={bcode} BlogTitle={BlogTitle} dir={BlogDir} withRouter={{RelativePath: `/blog/${ownerid}/${bcode}`}}/>
      <br/> 
      <BlogList />
    </div>
  );
};

export default Blog;
