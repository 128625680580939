
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBqwpLoOA1HGVZlbsZgHcRb4mzsL_4LyPA",
  authDomain: "fir-2880b.firebaseapp.com",
  databaseURL: "https://fir-2880b-default-rtdb.firebaseio.com",
  projectId: "fir-2880b",
  storageBucket: "fir-2880b.appspot.com",
  messagingSenderId: "947533838110",
  appId: "1:947533838110:web:56183ce01e3f2630e7fb0f",
  measurementId: "G-42MQKYHYPX"
};

export default firebaseConfig