import { keys } from '@material-ui/core/styles/createBreakpoints'
import database from './firebase'

const addBlog=(ownerId,blogTitle, dir)=>{
    return new Promise((resolve, reject) => {
        database.ref(`blogs/${ownerId}`).push({blogTitle, dir})
        .then((bcodeUrl)=>{
      const bcodePathArr=     (bcodeUrl+"").split('/')
       resolve(bcodePathArr[bcodePathArr.length-1])
        }).catch((e)=>{
            reject(e)
        })
    })

}

const getBlogTitle=(ownerId,bCode)=>{
    return new Promise((resolve, reject) => {
    database.ref(`blogs/${ownerId}/${bCode}`).once('value')
    .then((snapshot) => {
        snapshot.val()?resolve( snapshot.val()):reject("no name")
    }).catch((e)=>{reject(e)})
})}
const getBlogList=()=>{
    return new Promise((resolve, reject) => {
        let blogList=[]
        database.ref(`blogs`).once('value')
        .then((snapshot) => {
        const    blogObj=snapshot.val()
            Object.keys(blogObj).forEach((key)=>{
                Object.keys(blogObj[key]).forEach((k)=>{
                    blogList.push({title:blogObj[key][k].blogTitle,ownerId:key, bCode:k, dir:blogObj[key][k].dir})
            })
        })
        resolve(blogList.reverse())
    }).catch((e)=>{reject(e)})
  
   
})


}





export{
    addBlog,
    getBlogTitle,
    getBlogList
   
}

// export default DBconnection