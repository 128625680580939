import React from 'react'

const UsageProcedures=()=>{
    return(<div>
        <h2>Usage procedures</h2>
        Do not bring up the contents of defamation, violence, deception or harm to any party <br />
The responsibility for the content lies with the uploader (writer) of the content only and in full<br />
The author will fully bear the consequences and damages that will be caused as a result of the publication of his articles<br />
No copyrighted content may be uploaded, including any upload of an image subject to permission to upload<br />
The system has the right to any content uploaded to the site, and it may do so at will<br />
The system has the full right to download any content from the site, and without the need to give an explanation and report on it<br />
Any upload of content, is required to meet the criteria and conditions of accepted local law<br />
The decisive and only agreed factor for each hearing is the court of Kollel Eretz Hemda only<br />
In any case of suspicion of copyright / infringement or any inappropriate content, you can contact <a href="https://motto.co.il/" >Motto Israel</a>  https://motto.co.il/<br />
    </div>)
}

export default UsageProcedures