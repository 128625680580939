import React, { useContext } from 'react';

import { BrowserRouter, Route, Switch, Link, NavLink, Redirect } from 'react-router-dom';
import Blog from './components/blog'
import BlogList from './components/BlogList'
import AddBlog from './components/addBlog'
// import App from './App'
import Home from './components/home'
import Navbar from './components/Navbar';
import UsageProcedures from './components/UsageProcedures'
import Footer from './components/footer'
const AppRouter = () => {
    
    
  return(
  <BrowserRouter>
    <div>
   
<Navbar />

      <Switch>
        <Route path="/" component={Home} exact={true} />
        <Route path="/blog/:ownerid/:bcode" component={Blog}  />
        {/* <Route path="/blog/:ownerid/:bcode/:pcode" component={Blog} exact={true} /> */}
        <Route path="/add-blog" component={AddBlog} />
        <Route path="/blog-list" component={BlogList} />
       
        <Route path="/Usage-Procedures" component={UsageProcedures} />



         {/* <Route component={NotFoundPage} /> */}
      </Switch>
      <Footer />
    </div>
  </BrowserRouter>

);
}
export default AppRouter;
