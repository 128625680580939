import React, { useState, useEffect } from "react";
import { getBlogList } from "../firebase/DBConnection";
import "./addBlog.css";
import Ads from 'dugoly-shared-advertising'
import img from './images/bloging.jpg'

const BlogList = () => {
  const [BlogList, setBlogList] = useState([]);
  useEffect(() => {
    getBlogList().then((blogList) => {
      setBlogList(blogList);
    }).catch((e)=>console.log("catch> e:  ",e))
  }, []);

  return (
    <div className="BlogListMargin">
      <div style={{
                        backgroundImage:`url(${img})` , backgroundRepeat: 'no-repeat'
                    }}>
      <Ads 
      imgPath="http://www.easydone.online/uploads/-M_PtQR42NCr_cSbLWgb/6b052fa6-9861-4ec7-8cf6-2c58378ca84a.gif"
link="https://motto.co.il/Promotion" 
language="he"  />
        <h2 style={{color:"white"}}>All the Inspiring Blogs from Different Authors</h2>
        {BlogList.map((blog, i) => {
          return (
            <div key={i}>   
              <li>
                <button className="large ui button" style={{width:"400px", height:"40px", backgroundColor:"white"}}>
                  <a href={`/blog/${blog.ownerId}/${blog.bCode}`}>
                    {blog.title}
                  </a>
                </button>
              </li>
              <br />{" "}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BlogList;
