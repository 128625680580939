import react, { useState } from "react";
import "./addBlog.css";
import { addBlog } from "../firebase/DBConnection";
import Ads from 'dugoly-shared-advertising'
const AddBlog = () => {
  const [BlogTitle, setBlogTitle] = useState("");
  const uid = getCookie("uid");
  const dugolyToken = getCookie("dugolyToken");

  const handleChange = (e) => {
    setBlogTitle(e.target.value);
  };

  const onclick = (e) => {
    e.preventDefault()
    if (!uid || !dugolyToken) {
      alert("you have to login in order to open a blog");
    } else {
      if(BlogTitle.length<4){
        alert("A full name should be given to the blog")
      }else{
      addBlog(uid, BlogTitle, DirLtr?"ltr":"rtl")
        .then((bcode) => {
          alert(
            "The blog is added \n   Everyone is waiting to hear what you have to say"
          );
          window.location.href = `/blog/${uid}/${bcode}`;
        })
        .catch((e) => {
          alert("error:  " + e + "\n Please try again later");
        });
    }}
  };
  const [DirLtr, setDirLtr]=useState(true)
const Radiocomponent = ({ value, setDirLtr }) => ( 
  <div onChange={()=>{setDirLtr(!DirLtr)}}>
    <br />
    Select the writing direction <br />
    <input type="radio" value="LTR" name="dir" defaultChecked={DirLtr}/> From left to right<br />
    <input type="radio" value="RTL" name="dir" defaultChecked={!DirLtr} /> From right to left<br />
  </div>
);


  return (
    <div>
      <div className="head background-pic">
            
        <h1>Add Blog</h1>
      </div>

      <div className="addBlogForm">
        <form className="ui form">
          <div className="field">
            <label>Please enter the name of the blog</label>
            <textarea
             rows="1"
              onChange={handleChange}
              value={BlogTitle}
              placeholder="the name of the blog"
            ></textarea>
              <Radiocomponent  value={DirLtr} setDirLtr={setDirLtr}/>
          </div>
          <button
            className="ui button primary button_width"
            type="submit"
            onClick={onclick}
          >
            click for your new blog
          </button>
        </form>
        <br />
        <Ads 
      imgPath="http://www.easydone.online/uploads/-MO0rfu7iS6SO7iFQS2x/68a5ce0f-1218-413d-a5cb-914e7476db59.gif"
link="https://dugoly.com/Documentation/readme/dugoly-shared-advertising.html" 
language="en"  />
<br /> <br/>
 <Ads 
      imgPath="http://www.easydone.online/uploads/-MO0rfu7iS6SO7iFQS2x/619eb761-2457-4359-a61c-ec6351a0b0a9.gif" 
link="http://www.moreshetgalil.co.il/BRPortal/br/P100.jsp" 
language="he"  />
      </div>
    </div>
  );
};

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default AddBlog;
