import React from 'react';
import './home.css';
import Ads from 'dugoly-shared-advertising'

const home=()=>{

    return(<div>
    <section className="colored-section" id="title">

    <div className="container-fluid">

    <div className="row">

      <div className="col-lg-12">
        <h1 className="big-heading">In your writing you express the intensity of the sense of life</h1>
      </div>

      

    </div>
    </div>
    <Ads 
      imgPath="http://www.easydone.online/uploads/-M_PtQR42NCr_cSbLWgb/6b052fa6-9861-4ec7-8cf6-2c58378ca84a.gif"
link="https://motto.co.il/Promotion" 
language="he"  />
<br /> <br /> 
 <Ads 
      imgPath="http://www.easydone.online/uploads/-MO0rfu7iS6SO7iFQS2x/3df47c96-f752-420b-ad27-814a61834fcf.gif" 
link="https://treasurehunter.click/" 
language="he"  />
<br /><br /> 
<Ads 
      imgPath="http://www.easydone.online/uploads/-MO0rfu7iS6SO7iFQS2x/c9233da4-9d4e-4341-a478-c50fea85abd7.gif"
link="https://overthehorizon.site/" 
language="he"  />
<br /> <br /> 
 <Ads 
      imgPath="http://www.easydone.online/uploads/-M_PtQR42NCr_cSbLWgb/3150dde5-e6f4-4979-b870-dca3622c2dd9.gif" 
link="https://directdone.com/" 
language="en"  />
    </section>



    </div>)

}

export default home
