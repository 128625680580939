import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import Login from "dugoly-login";

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <div>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            Blogy.click 
            <img src="../../favicon.ico" width="40px" height="40px" />
          
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/blog-list"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Blog List
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/add-blog"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Add Blog
              </Link>
            </li>

            <li className="nav-item loginButton">
              <Login />
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
